import { useState } from "react";
import { Link } from "react-router-dom";

export const AboutUs = () => {
  const [selectedTab, setSelectedTab] = useState(1);

  const tabs = [
    {
      id: 1,
      title: "Our Approach",
      description:
        "Kadaa Limited was founded with a mission to lead the logistics industry towards a more sustainable and eco-conscious direction. By harnessing the potential of electric vehicles, we aim to reduce our carbon footprint and promote a cleaner, greener tomorrow. Our passion for sustainability drives every aspect of our logistics solutions, from local deliveries to long-haul transportation.",
    },
    {
      id: 2,
      title: "Our Goal",
      description:
        "At Kadaa Limited, we embark on a remarkable journey towards a shared vision - to be the leading force in sustainable logistics solutions that redefine the industry's environmental impact. Our goal is to pioneer a better tomorrow, where logistics operations not only deliver excellence but also contribute to a healthier, greener planet for generations to come.",
    },
  ];

  return (
    <div id="about" className="w-full min-h-[50vh] flex items-center my-10">
      <div className="w-[90%] 2xl:w-[60%] mx-auto">
        <div className="flex flex-col md:flex-row item-center justify-between h-full w-full">
          {/* left */}
          <div className="w-full md:w-[48%] h-full">
            <div className="bg-about_us bg-contain bg-center h-[15rem] md:h-[30rem] w-full bg-no-repeat" />
          </div>
          {/* right */}
          <div className="w-full md:w-[48%] mt-8 md:mt-0 h-auto flex flex-col justify-center">
            <h1 className="text-3xl font-medium mb-4 md:mb-0">About Us</h1>
            <p className="text-sm text-gray-500">
              At Kadaa Limited, we are committed to promoting sustainability in
              everything we do. As a logistics company, we recognize that our
              operations have an impact on the environment. That's why we have
              made it our mission to provide environmental logistics solutions
              that not only meet our customers' needs but also minimize our
              environmental footprint.
            </p>
            {/* tabs */}
            <div className="w-full flex items-center gap-5 my-5">
              {/* tab item */}
              {tabs.map((tab) => (
                <button
                  onClick={() => setSelectedTab(tab.id)}
                  className={`rounded-full w-[45%] md:w-[32%] py-4 items-center justify-center flex ${
                    selectedTab === tab.id
                      ? "bg-primary-500 text-white"
                      : "bg-gray-100"
                  } `}
                  key={tab.id}
                >
                  {tab?.title}
                </button>
              ))}
            </div>
            <p className="text-base text-gray-500">
              {tabs.filter((tab) => tab.id === selectedTab)[0].description}
            </p>
            <p>
              <Link
                className="text-primary-500 text-underline"
                to={selectedTab === 1 ? "/about" : "/our-goals"}
              >
                Read More.
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

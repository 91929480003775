import { IApp } from "@app/interfaces/app";
import { createSlice } from "@reduxjs/toolkit";

const appInitState: IApp = {
  showPageLoader: false,
  quote: {
    pickup: "",
    delivery: "",
    weight: "",
    country: "",
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState: appInitState,
  reducers: {
    showpageLoader: (state, action: { payload: boolean }) => {
      return { ...state, showPageLoader: action.payload };
    },
    saveQuoteDetails: (
      state,
      action: {
        payload: {
          pickup: string;
          delivery: string;
          weight: string;
          country: string;
        };
      }
    ) => {
      return {
        ...state,
        quote: action.payload,
      };
    },
    clearQuoteDetails: (state) => {
      return { ...state, quote: appInitState.quote };
    },
  },
});

export const { showpageLoader, saveQuoteDetails, clearQuoteDetails } =
  appSlice.actions;
export default appSlice.reducer;

import useDisclosure from "@hooks/useDisclosure";
import { Button } from "@lib/Button";
import { Input } from "@lib/Input";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import CantFindTrackingNumberModal from "./CantFindTrackingNumberModal";

export const TrackForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const validationSchema = yup.object().shape({
    tracking_code: yup
      .string()
      .required("Please provide a shipment tracking code."),
  });

  const initialValues = {
    tracking_code: "",
  };

  const { values, errors, handleChange, dirty, isValid } = useFormik({
    validationSchema,
    initialValues,
    onSubmit: () => {},
  });

  const handleTrackShipment = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      navigate(`/track-parcel?tracking_code=${values.tracking_code}`);
    }, 1000);
  };

  const disabled = !dirty || !isValid;

  return (
    <div className="py-6 px-10">
      <h1 className="text-xl text-primary-500 font-bold">Track your parcel</h1>
      <div className="my-3">
        <Input
          label="Enter your tracking number"
          name="tracking_code"
          onChange={handleChange}
          error={errors?.tracking_code}
          value={values.tracking_code}
        />
      </div>
      <div className="flex md:flex-row flex-col items-center gap-4">
        <div className="w-full md:w-1/4">
          <Button
            label="Track"
            onClick={handleTrackShipment}
            disabled={disabled}
            isLoading={isLoading}
          />
        </div>
        <span
          onClick={onOpen}
          className="underline underline-offset-4 text-sm text-primary-500 cursor-pointer"
        >
          Can’t find tracking number?
        </span>
      </div>
      <CantFindTrackingNumberModal isOpen={isOpen} onClose={onClose} />
    </div>
  );
};

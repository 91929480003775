import { useAppSelector } from "@hooks/useAppSelector";
import useDisclosure from "@hooks/useDisclosure";
import usePostcodeValidator from "@hooks/usePostalcodeValidator";
import { Input } from "@lib/Input";
import { Select } from "@lib/Select";
import { useAddressBook } from "@network/hooks/shipment";
import { ICreateShipment } from "@network/interface";
import { FormikErrors } from "formik";
import { CountryCode } from "postcode-validator";
import React from "react";
import { twMerge } from "tailwind-merge";

interface IProps {
  handleChange: any;
  values: ICreateShipment;
  errors: FormikErrors<ICreateShipment>;
  streets: { key: string; value: string }[];
  isLoadingShipperStreets?: boolean;
  handleInputChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: any;
}

export const ShipperDetailsForm = ({
  errors,
  handleChange,
  values,
  streets,
  isLoadingShipperStreets,
  handleInputChange,
  setFieldValue,
}: IProps) => {
  const { addresses } = useAddressBook();
  const { isValid: from_valid } = usePostcodeValidator(
    values.shipper_postal_code,
    CountryCode.GB
  );
  const handleUseLocalAddress = useDisclosure();

  const isLoggedIn = useAppSelector((state) => state.user.access_token);

  const handleNumberChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    if (isNaN(value)) {
      return;
    } else {
      handleChange({
        target: { name, value },
      });
    }
  };

  return (
    <div>
      <p className="text-lg">Please fill in the shipper details (sender)</p>
      <div className="mt-5">
        <div className="w-full flex flex-col md:flex-row justify-between">
          <div className="w-full md:w-[48%]">
            <Input
              label="Name"
              placeholder="Shipper name"
              required
              name="shipper_fullname"
              onChange={handleChange}
              value={values.shipper_fullname}
              error={errors?.shipper_fullname}
            />
          </div>
          <div className="w-full md:w-[48%]">
            <Input
              label="Email Address"
              placeholder="shipper@gmail.com"
              required
              type="email"
              name="shipper_email_address"
              onChange={handleChange}
              value={values.shipper_email_address}
              error={errors?.shipper_email_address}
            />
          </div>
        </div>
        <div className="w-full flex flex-col md:flex-row justify-between">
          <div className="w-full md:w-[48%]">
            <Select
              options={[{ key: "United Kingdom", value: "UK" }]}
              value={values.shipper_country}
              error={errors.shipper_country}
              label="Country"
              name="shipper_country"
              placeholder="Select shipper country"
              onChange={handleChange}
            />
          </div>
          <div className="w-full md:w-[48%]">
            <Input
              label="Mobile Number"
              placeholder="+44 567 890 1112"
              required
              max={12}
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              name="shipper_phone_number"
              onChange={handleNumberChange}
              value={values.shipper_phone_number}
              error={errors?.shipper_phone_number}
            />
          </div>
        </div>
        <div
          className={twMerge(
            "w-full flex flex-col md:flex-row justify-between",
            isLoggedIn ? "mb-4" : ""
          )}
        >
          <div className="w-full">
            {handleUseLocalAddress.isOpen ? (
              <Select
                label="Pickup Address"
                options={addresses?.myAddressBook?.map((add: any) => ({
                  key: add.postal_code,
                }))}
                onChange={(e) => {
                  setFieldValue("shipper_postal_code", e.target.value);
                  handleUseLocalAddress.onClose();
                }}
                placeholder="Select Pickeup Address"
              />
            ) : (
              <Input
                label="Postal Code"
                placeholder="Postal code"
                required
                name="shipper_postal_code"
                onChange={handleInputChange}
                value={values.shipper_postal_code}
                error={
                  errors?.shipper_postal_code || from_valid
                    ? ""
                    : "Invalid postal code"
                }
              />
            )}
            {isLoggedIn ? (
              <p className="text-xs">
                Use address from{" "}
                <span
                  className={twMerge(
                    "text-primary-500 font-bold cursor-pointer capitalize",
                    handleUseLocalAddress.isOpen ? "text-red-500" : ""
                  )}
                  onClick={() => {
                    if (handleUseLocalAddress.isOpen) {
                      handleUseLocalAddress.onClose();
                    } else {
                      handleUseLocalAddress.onOpen();
                    }
                  }}
                >
                  {handleUseLocalAddress.isOpen ? "Cancel" : "Address Book"}
                </span>
              </p>
            ) : null}
          </div>
        </div>
        {!handleUseLocalAddress.isOpen ? (
          <div className="w-full flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-[48%]">
              <Select
                options={streets}
                label="Street Name"
                placeholder="Select Street"
                required
                name="shipper_street_name"
                disabled={isLoadingShipperStreets || streets.length <= 0}
                onChange={handleChange}
                value={values.shipper_street_name}
                error={errors?.shipper_street_name}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

import {
  AirFreightIcon,
  CustomerClearanceIcon,
  LocalShippingIcon,
  ProjectExhibitionIcon,
  SeaTransportIcon,
  WarehousingIcon,
} from "@assets/icons";
import React from "react";

export const Services = () => {
  const services = [
    {
      title: "Local Delivery Services",
      id: 1,
      description:
        "We prioritize speed, efficiency, and sustainability, ensuring your packages reach their destination swiftly and responsibly. Trust us for reliable, green, and hassle-free local deliveries.",
      icon: <LocalShippingIcon />,
    },
    {
      title: "Air Fright Services",
      id: 3,
      description:
        "Unlock the world with our swift and secure air freight services. We specialize in seamless global cargo transportation, ensuring your shipments reach any destination with speed and reliability.",
      icon: <AirFreightIcon />,
    },
    {
      title: "Customer Clearance",
      id: 5,
      description:
        "Smooth the way for your shipments with our expert customer clearance services. We handle all customs procedures efficiently, ensuring your goods pass seamlessly through international borders. Trust us to navigate complex regulations, saving you time and effort.",
      icon: <CustomerClearanceIcon />,
    },
    {
      title: "Sea Freight Services",
      id: 6,
      description:
        "Dive into efficient and cost-effective sea freight solutions with us. Our expert team manages your cargo across oceans, ensuring safe and timely deliveries worldwide.",
      icon: <SeaTransportIcon />,
    },
  ];

  return (
    <div
      id="services"
      className="w-full rounded-tr-[3rem] rounded-tl-[3rem] md:rounded-tr-[5rem] md:rounded-tl-[5rem] -mt-[5rem] bg-white z-[100] relative min-h-[80vh] xl:min-h-fit"
    >
      <div className="w-[90%] 2xl:w-[60%] mx-auto">
        <div className="w-full flex items-center justify-center py-16">
          <h1 className="text-2xl md:text-3xl font-bold">
            Our Logistic Services
          </h1>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-2 gap-6 md:gap-12">
          {services.map((service) => (
            <div key={service.id} className="">
              <div className="w-20 h-20 flex items-center bg-gray-100 justify-center rounded-md">
                {service.icon}
              </div>
              <h1 className="text-lg md:text-2xl font-semibold mt-4 md:mt-8 mb-3 capitalize">
                {service?.title}
              </h1>
              <p className="text-sm md:text-base text-gray-400">
                {service?.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

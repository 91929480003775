import { Modal } from "@components/Modal";
import { Button } from "@lib/Button";
import { Crisp } from "crisp-sdk-web";
import React from "react";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const CantFindTrackingNumberModal = ({ isOpen, onClose }: IProps) => {
  const onContactUsClick = () => {
    onClose();
    Crisp.chat.open();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col items-center">
        <div className="w-full flex items-center justify-center border-b border-b-gray-300 py-3">
          <h1 className="text-2xl font-bold uppercase">
            Can't find tracking number!.
          </h1>
        </div>
        <div className="mt-3">
          <p>A tracking code should be have been sent to your mail.</p>
          <p>Ensure to check the trash and spam folders.</p>
          <p>
            If still not found!,{" "}
            <span
              onClick={onContactUsClick}
              className="text-primary-500 underline underline-offset-2 cursor-pointer"
            >
              contact kadaa support
            </span>
          </p>
          <div className="flex items-center gap-3 mt-5">
            <Button label="Cancel" className="bg-red-500" onClick={onClose} />
            <Button label="Contact Support" onClick={onContactUsClick} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CantFindTrackingNumberModal;

import React, { useEffect, useState, useRef } from 'react';

export type Step = {
  description: string;
  completed: boolean;
  higlighted: boolean;
  selected: boolean;
};

export const Stepper = ({
  steps,
  currentStep,
}: {
  steps: string[];
  currentStep: number;
}) => {
  const [newStep, setNewStep] = useState<Array<Step>>([]);
  const stepRef = useRef<any>();

  const updateStep = (stepIndex: number, steps: Array<Step>) => {
    //
    const newSteps = [...steps];
    let count = 0;
    while (count < newSteps.length) {
      // current step
      if (count === stepIndex) {
        newSteps[count] = {
          ...newSteps[count],
          higlighted: true,
          selected: true,
          completed: false,
        };
        count++;
      }
      // step completed
      else if (count < stepIndex) {
        newSteps[count] = {
          ...newSteps[count],
          higlighted: false,
          selected: true,
          completed: true,
        };
        count++;
      }
      // step pending
      else {
        newSteps[count] = {
          ...newSteps[count],
          higlighted: false,
          selected: false,
          completed: false,
        };
        count++;
      }
    }
    return newSteps;
  };

  useEffect(() => {
    // create object
    const stepsState = steps.map((step, index) =>
      Object.assign(
        {},
        {
          description: step,
          completed: false,
          higlighted: index === 0 ? true : false,
          selected: index === 0 ? true : false,
        }
      )
    );
    stepRef.current = stepsState;
    const current = updateStep(currentStep - 1, stepRef.current);
    setNewStep(current);
  }, [steps, currentStep]);

  const displaySteps = newStep.map((step, index) => (
    <div
      key={index}
      className={
        index !== newStep.length - 1
          ? `w-full flex items-center`
          : `flex items-center`
      }>
      {/* Steps Display */}
      <div className='relative flex flex-col items-center text-primary-500'>
        {/* Display Number */}
        <div
          className={`rounded-full transition duration-500 ease-in-out border-2 border-gray-300 h-12 w-12 flex items-center justify-center py-3 ${
            step.selected
              ? 'bg-primary-500 text-white font-bold border border-primary-500'
              : ''
          }`}>
          {step.completed ? (
            <span className='text-white font-bold text-xl'>&#10003;</span>
          ) : (
            index + 1
          )}
        </div>
        {/* Display Description */}
        <div
          className={`absolute top-0 text-center mt-14 w-auto md:w-32 text-xs font-medium uppercase ${
            step.higlighted ? 'text-gray-900' : 'text-gray-400'
          }`}>
          {step.description}
        </div>
      </div>
      <div
        className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
          step.completed ? 'border-primary-500' : 'border-gray-300'
        }`}>
        {/* Display Horizontal Line */}
      </div>
    </div>
  ));

  return (
    <div className={`md:mx-4 p-4 flex justify-between items-center`}>
      {displaySteps}
    </div>
  );
};

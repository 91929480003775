export enum Endpoints {
  LOGIN = "/auth/login",
  FORGOT_PASSWORD = "/auth/forgot-password",
  RESET_PASSWORD = "/auth/reset-password",
  SETUP_ACCOUNT = "/auth/setup-account",
  VERIFY_RESET_ACCOUNT_OTP = "/auth/verify-account-reset-otp",
  REGISTER_WITH_EMAIL = "/auth/register",
  VERIFY_ACCOUNT_OTP = "/auth/verify-email",
  RESEND_ACCOUNT_OTP = "/auth/resend-email-otp",
  CREATE_PROFILE = "/auth/create-profile",
  GET_QUOTE = "/shipment/get-quote",
  GET_USER_SHIPMENTS = "/shipment/my-shipments",
  TRACK_SHIPMENT = "/shipment/track-shipment",
  CREATE_SHIPMENT = "/shipment/create-shipment",
  POSTAL_CODE_FROM_STREET = "/shipment/postal_code_streets",
  GET_USER_ADDRESS_BOOK = "/shipment/my-address-book",
  GET_USER_INVOICES = "/invoice/my-invoices",
  UPDATE_USER = "/account/update",
  CHANGE_PASSWORD = "/account/change-password",
}

export enum QueryKeys {
  GET_USER_SHIPMENTS = "GET_USER_SHIPMENTS",
  TRACK_SHIPMENT = "TRACK_SHIPMENT",
  POSTAL_CODE_FROM_STREET = "POSTAL_CODE_FROM_STREET",
  GET_USER_INVOICES = "GET_USER_INVOICES",
}

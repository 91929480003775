import { logoutUser } from "@app/index";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";
import { httpClient } from "@network/api";
import { AxiosInstance } from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useHttpPrivate = (): AxiosInstance => {
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const requestInterceptor = httpClient.interceptors.request.use(
      async (config: any) => {
        const accessToken = user?.access_token;
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
      },
      (err: any) => {
        return Promise.reject(err);
      }
    );
    const responseInterceptor = httpClient.interceptors.response.use(
      async (response) => {
        return response;
      },
      async (err: any) => {
        const { config, response } = err;
        if (response?.status === 401 && !config?.__isRetryRequest) {
          config.__isRetryRequest = true;
          // refresh access token
          return httpClient(config);
        }
        if (
          response?.status === 401 &&
          response?.data?.message === "Session Expired!, Please login again."
        ) {
          dispatch(logoutUser());
          navigate("/auth/login");
        }
        return Promise.reject(err);
      }
    );

    return () => {
      httpClient.interceptors.response.eject(responseInterceptor);
      httpClient.interceptors.request.eject(requestInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return httpClient;
};

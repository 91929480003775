import React from "react";

export const Solution = () => {
  return (
    <div id="solutions" className="w-full min-h-[48vh] md:min-h-[80vh] 2xl:min-h-[60vh] rounded-tr-[3rem] rounded-tl-[3rem] md:rounded-tr-[5rem] md:rounded-tl-[5rem] bg-dark-500 text-white relative h-full">
      {/* overlay texts */}
      <div className="w-full absolute z-[10] top-0 left-0 h-full flex justify-between px-[2rem] md:px-[3rem] xl:px-[5rem] py-12">
        <div className="w-2/4">
          <h1 className="text-2xl md:text-[4rem] md:leading-[3.8rem] font-bold">
            Logistics Solutions for Businesses to Address Delivery Challenges
          </h1>
        </div>
        <div className="w-[40%] md:w-1/4 2xl:w-[1/2]">
          <p className="text-xs md:text-base xl:text-xl">
            At Kadaa Limited, we believe that sustainability and profitability
            go hand in hand. By working with us, you can reduce your carbon
            footprint and contribute to a more sustainable future.
          </p>
        </div>
      </div>
      {/* overlay app type */}
      <div className="flex w-full absolute z-[10] bottom-[5.3rem] md:bottom-[7rem] left-0 items-center justify-center">
        <h1 className="text-center text-3xl md:text-[4rem] xl:text-[10rem] tracking-[1rem] md:tracking-[2rem] xl:tracking-[3rem] 2xl:tracking-[5rem] font-extrabold opacity-[0.1]">
          LOGISTICS
        </h1>
      </div>
      <div className="bg-solution_banner md:bg-[5rem] xl:bg-[22rem] 2xl:bg-[45rem] bg-no-repeat bg-contain w-full h-[45rem] md:h-[36rem] xl:h-[45rem] 2xl:h-[60rem] absolute top-0 right-0 z-[1]" />
    </div>
  );
};

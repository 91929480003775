import { useEffect, useState } from "react";
import { postcodeValidator, CountryCode } from "postcode-validator";

const usePostcodeValidator = (postCode: string, country: CountryCode) => {
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (postCode !== "" && postCode !== undefined) {
      setIsValid(postcodeValidator(postCode, country));
    }
  }, [postCode, country]);

  return {
    isValid,
  };
};

export default usePostcodeValidator;

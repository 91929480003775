import { AboutUs } from "@components/AboutUs";
import { Hero } from "@components/Hero";
import { Services } from "@components/Services";
import { Solution } from "@components/Solution";
import React from "react";

export const HomePage = () => {
  return (
    <div className="w-full">
      <Hero />
      <AboutUs />
      <Solution />
      <Services />
      <div className="w-full h-[25vh] md:h-[40vh] bg-footer_banner bg-no-repeat bg-[-5rem] md:bg-center bg-cover mt-12 md:mt-24" />
    </div>
  );
};

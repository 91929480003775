import axios from "axios";

// export const BASE_URL = "http://192.168.1.54:5001/api/v1";
export const BASE_URL = "https://api.kadaalimited.com/api/v1";
// export const BASE_URL = "https://kadaa-service.onrender.com/api/v1";

const headers = {};

export const httpClient = axios.create({
  baseURL: BASE_URL,
  headers,
});
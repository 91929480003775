import { Footer } from "@components/Footer";
import { Header } from "@components/Header";
import { useAppSelector } from "@hooks/useAppSelector";
import { PageLoader } from "@lib/Loader";
import { Outlet } from "react-router-dom";

export const HomeLayout = () => {
  const { showPageLoader } = useAppSelector((state) => state.app);
  return (
    <>
      {showPageLoader ? <PageLoader show={showPageLoader} /> : null}
      <Header />
      <div className="flex flex-col justify-between min-h-screen overflow-x-hidden">
        <Outlet />
        <Footer />
      </div>
    </>
  );
};

import React from "react";

export const Modal = ({
  children,
  isOpen,
  onClose,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <>
      {isOpen ? (
        <div className="bg-black bg-opacity-80 flex justify-center items-center fixed top-0 right-0 bottom-0 left-0 z-[99999] w-screen h-screen">
          <div className="bg-white w-1/2 py-4 rounded-md">{children}</div>
        </div>
      ) : null}
    </>
  );
};

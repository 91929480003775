import { Button } from "@lib/Button";
import React from "react";

export const StepperControl = ({
  handleClick,
  currentStep,
  steps,
  isLoading,
  disabled,
  canGoNext,
  type,
}: {
  currentStep: number;
  handleClick: (direction: "prev" | "next", type?: "proceed" | "draft") => void;
  steps: string[];
  isLoading?: boolean;
  disabled?: boolean;
  canGoNext?: boolean;
  type: "draft" | "proceed";
}) => {
  return (
    <div className="flex justify-between items-center mt-4">
      <div className="w-full flex gap-4 ">
        <div>
          <Button
            label="Go Back"
            className={`!bg-transparent !border !border-solid !border-primary-500 hover:border-primary-600 !text-primary-500 ${
              currentStep === 1 ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={() => handleClick("prev")}
          />
        </div>
        <div>
          <Button
            label={
              currentStep === steps.length + 1 - 1
                ? "SAVE TO DRAFT"
                : "Continue"
            }
            onClick={() => handleClick("next", "draft")}
            isLoading={type === "draft" ? isLoading : false}
            disabled={
              currentStep === steps.length + 1 - 1 ? disabled : canGoNext
            }
          />
        </div>
      </div>
      {currentStep === steps.length + 1 - 1 ? (
        <div className="w-[40%]">
          <Button
            label={currentStep === steps.length + 1 - 1 ? "Proceed" : ""}
            onClick={() => handleClick("next", "proceed")}
            isLoading={type === "proceed" ? isLoading : false}
            disabled={
              currentStep === steps.length + 1 - 1 ? disabled : canGoNext
            }
          />
        </div>
      ) : null}
    </div>
  );
};

import { useAppSelector } from "@hooks/useAppSelector";
import useDisclosure from "@hooks/useDisclosure";
import usePostcodeValidator from "@hooks/usePostalcodeValidator";
import { Button } from "@lib/Button";
import { Input } from "@lib/Input";
import { Select } from "@lib/Select";
import { useAddressBook } from "@network/hooks/shipment";
import { useFormik } from "formik";
import { CountryCode } from "postcode-validator";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import * as yup from "yup";

export const SendParcelForm = () => {
  const navigate = useNavigate();
  const handleUseLocalAddress = useDisclosure();
  const handleUseLocalDeliveryAddress = useDisclosure();

  const { addresses } = useAddressBook();

  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = yup.object().shape({
    country: yup.string().required("Address is required!."),
    postal_code_from: yup.string().required("Postal code is required!."),
    postal_code_to: yup.string().required("Postal code is required!."),
    weight: yup.number().required("Please provide a valid weight in KG!."),
  });

  const initialValues = {
    country: "UK",
    postal_code_from: "",
    postal_code_to: "",
    weight: 1,
  };

  const { values, errors, handleChange, dirty, isValid, setFieldValue } =
    useFormik({
      validationSchema,
      initialValues,
      onSubmit: () => {},
    });

  const { isValid: from_valid } = usePostcodeValidator(
    values.postal_code_from,
    CountryCode.GB
  );

  const { isValid: to_valid } = usePostcodeValidator(
    values.postal_code_to,
    CountryCode.GB
  );

  const handleGetQuote = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      navigate(
        `/get-quotes?country=${values.country}&weight=${values.weight}&pickup_postal_code=${values.postal_code_from}&delivery_postal_code=${values.postal_code_to}`
      );
    }, 2500);
  };

  const disabled = !dirty || !isValid;

  const handleInputChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    const capitalizedValue = value.toUpperCase();
    handleChange({
      target: { name, value: capitalizedValue },
    });
  };

  const isLoggedIn = useAppSelector((state) => state.user.access_token);

  const handleNumberChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    if (isNaN(value)) {
      return;
    } else {
      handleChange({
        target: { name, value },
      });
    }
  };

  return (
    <div className="py-6 px-10">
      <h1 className="text-xl text-primary-500 font-bold">Send a parcel</h1>
      <h4 className="text-sm text-gray-400 mt-2">
        Ship packages effortlessly, starting at £2.00 (excluding VAT)
      </h4>
      <div className="flex md:flex-row flex-col justify-between w-full gap-5 mt-3">
        <div className="w-full md:w-[100%]">
          <Select
            options={[{ key: "United Kingdom", value: "UK" }]}
            value={values.country}
            error={errors.country}
            name="country"
            placeholder="Select country"
            onChange={handleChange}
          />
        </div>
      </div>
      <div
        className={twMerge(
          "flex md:flex-row flex-col justify-between w-full gap-5 mt-3",
          isLoggedIn ? "mb-4" : ""
        )}
      >
        <div className="w-full md:w-[47%]">
          {handleUseLocalAddress.isOpen ? (
            <Select
              label="Pickup Address"
              options={addresses?.myAddressBook?.map((add: any) => ({
                key: add.postal_code,
              }))}
              onChange={(e) => {
                setFieldValue("postal_code_from", e.target.value);
                handleUseLocalAddress.onClose();
              }}
              placeholder="Select Pickeup Address"
            />
          ) : (
            <Input
              label="Pickup Postal Code"
              name="postal_code_from"
              onChange={handleInputChange}
              error={
                errors?.postal_code_from || from_valid
                  ? ""
                  : "Invalid postal code"
              }
              value={values.postal_code_from}
            />
          )}
          {isLoggedIn ? (
            <p className="text-xs">
              Use address from{" "}
              <span
                className={twMerge(
                  "text-primary-500 font-bold cursor-pointer capitalize",
                  handleUseLocalAddress.isOpen ? "text-red-500" : ""
                )}
                onClick={() => {
                  if (handleUseLocalAddress.isOpen) {
                    handleUseLocalAddress.onClose();
                  } else {
                    handleUseLocalAddress.onOpen();
                  }
                }}
              >
                {handleUseLocalAddress.isOpen ? "Cancel" : "Address Book"}
              </span>
            </p>
          ) : null}
        </div>
        <div className="w-full md:w-[47%]">
          {handleUseLocalDeliveryAddress.isOpen ? (
            <Select
              label="Delivery Address"
              placeholder="Select Delivery Address"
              options={addresses?.receiverAddressBook?.map((add: any) => ({
                key: add.postal_code,
              }))}
              onChange={(e) => {
                setFieldValue("postal_code_to", e.target.value);
                handleUseLocalDeliveryAddress.onClose();
              }}
            />
          ) : (
            <Input
              label="Delivery Postal Code"
              name="postal_code_to"
              onChange={handleInputChange}
              error={
                errors?.postal_code_from || to_valid
                  ? ""
                  : "Invalid postal code"
              }
              value={values.postal_code_to}
            />
          )}

          {isLoggedIn ? (
            <p className="text-xs">
              Use address from{" "}
              <span
                className={twMerge(
                  "text-primary-500 font-bold cursor-pointer capitalize",
                  handleUseLocalDeliveryAddress.isOpen ? "text-red-500" : ""
                )}
                onClick={() => {
                  if (handleUseLocalDeliveryAddress.isOpen) {
                    handleUseLocalDeliveryAddress.onClose();
                  } else {
                    handleUseLocalDeliveryAddress.onOpen();
                  }
                }}
              >
                {handleUseLocalDeliveryAddress.isOpen
                  ? "Cancel"
                  : "Address Book"}
              </span>
            </p>
          ) : null}
        </div>
      </div>
      <div className="w-full md:w-[55%]">
        <Input
          label="Weight"
          type="number"
          min={1}
          pattern="[0-9]*"
          inputMode="numeric"
          suffix={<span>KG</span>}
          name="weight"
          onChange={handleNumberChange}
          error={errors?.weight}
          value={values.weight}
        />
      </div>
      <div className="flex md:flex-row flex-col items-center gap-4">
        <div className="w-full md:w-1/4">
          <Button
            label="Get Quote"
            onClick={handleGetQuote}
            isLoading={isLoading}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};
